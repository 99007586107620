const utils = require("../utils");
const zippiGetURL = '/api/zippi-get';
const zippiPostURL = '/api/zippi-post';
const defaultURL = '/api/default-configuration';
const currentURL = '/api/current-configuration';
const deploymentURL = '/api/deployment-record';
const solutionsURL = '/api/zpaper-solutions';
const importActionsURL = '/api/import-actions';
const importZDocSetURL = '/api/import-zdocset';
const importTemplatesURL = '/api/import-templates';
const autodriveFormsURL = '/api/autodrive-forms';
const formImageURL = '/api/form-image';
const initializeOrgURL = '/api/initialize-org';
const updateDeploymentURL = '/api/update-deployment';

/* Cache for the deployement record */
let deployment = {};
export const zippiGet = zippiUrl => {
  let apiKey = deployment.zippiKey__c;
  let proxyUrl = `${zippiGetURL}?zippiUrl=` + encodeURI(zippiUrl);
  return utils.fetchApi(proxyUrl, apiKey);
};
export const zippiPost = zippiUrl => {
  let apiKey = deployment.zippiKey__c;
  return utils.postApi(zippiPostURL, {
    zippiUrl
  }, apiKey);
};
export const getDefaultConfigData = async () => {
  return utils.fetchApi(defaultURL);
};
export const getCurrentConfigData = async () => {
  return utils.fetchApi(currentURL);
};
export const getDeploymentRecord = async orgId => {
  if (orgId) {
    deployment = await utils.fetchApi(`${deploymentURL}?orgId=${orgId}`);
  }
  return deployment;
};
export const getSolutions = async () => {
  let orgId = deployment.Salesforce_Org__c;
  let orgSolutionsUrl = `${solutionsURL}?orgId=${orgId}`;
  return utils.fetchApi(orgSolutionsUrl);
};
export const getAutodriveForms = () => {
  let apiKey = deployment.zippiKey__c;
  return utils.fetchApi(autodriveFormsURL, apiKey);
};
export const getFormImage = formId => {
  let apiKey = deployment.zippiKey__c;
  let formImageUrl = `${formImageURL}?formId=${formId}`;
  return utils.fetchApi(formImageUrl, apiKey);
};
export const getSubGroups = masterId => {
  let zpServer = deployment.Server__c || 'gw.zpaper.com';
  let groupId = masterId || '00000';
  let zippiUrl = `https://${zpServer}/zippi/groups?groupId=-${groupId}*`;
  return zippiGet(zippiUrl);
};
export const importActions = depAssets => {
  let apiKey = deployment.zippiKey__c;
  return utils.postApi(importActionsURL, depAssets, apiKey);
};
export const importDocSet = () => {
  let apiKey = deployment.zippiKey__c;
  return utils.postApi(importZDocSetURL, {}, apiKey);
};
export const importTemplates = zipUrl => {
  let apiKey = deployment.zippiKey__c;
  return utils.postApi(importTemplatesURL, {
    zipUrl
  }, apiKey);
};
export const updateDeployment = async depRecord => {
  // Update Salesforce with this new record, which eventually updates Redis
  let output = await utils.postApi(updateDeploymentURL, depRecord);
  if (output && output.status === "success") {
    deployment = depRecord; // the deployment.jsp only returns XML from Salesforce
    return deployment;
  }
  throw new Error("Error updating zProd deployment record: " + JSON.stringify(output));
};
export const initializeOrg = async depRecord => {
  // Update zPaper, returning the new deployment record info
  let output = await utils.postApi(initializeOrgURL, depRecord);

  // Check the Deployment record that is returned for updated fields.
  if (output.Id && output.zippiKey__c && output.KBin_Master_ID__c) {
    await updateDeployment(output); // give redis a chance to catch up
    return output;
  }
  throw new Error("Error calling zippi /initialize endpoint: " + JSON.stringify(output));
};
export const clearRedisCache = orgId => {
  // Clear the Redis cache values for the specified SF org (or the current org)
  let zpServer = deployment.Server__c || 'gw.zpaper.com';
  let sfOrgId = orgId || deployment.Salesforce_Org__c;
  let zippiUrl = `https://${zpServer}/zippi/admin/cacheRefresh?orgId=${sfOrgId}`;
  return zippiPost(zippiUrl);
};