import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./remoteSites.html";
import zLightningElement from 'base/zLightningElement';
import { getRemoteSites, saveRemoteSites } from 'data/salesforceConfigService';
class RemoteSites extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.remoteSites = [];
  }
  connectedCallback() {
    super.connectedCallback();
    this.dirty = true; // Only save these fixed values the first time

    let verifyOrg = document.lightningWebComponents['verify-organization'];
    let deployment = verifyOrg && verifyOrg.getDeployment();
    if (deployment && deployment.Server__c) {
      getRemoteSites(deployment.Server__c).then(result => {
        this.remoteSites = result;
      }).catch(error => {
        console.error(error);
      });
    } else {
      console.error("Deployment Record is missing the 'Server__c' hostname!");
    }
  }
  async saveYourself() {
    if (!this.dirty) {
      this.completed = true;
      return;
    }
    await saveRemoteSites(this.remoteSites).then(results => {
      this.completed = true;
      this.dirty = false;
      return results;
    }).catch(errors => {
      alert(errors);
      return errors;
    });
  }
}
_registerDecorators(RemoteSites, {
  track: {
    remoteSites: 1
  }
})
export default _registerComponent(RemoteSites, {
  tmpl: _tmpl
});