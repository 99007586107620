import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./fileSecurity.html";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import zLightningElement from 'base/zLightningElement';
import { getFileTypes, saveFileTypes } from 'data/salesforceConfigService';
class FileSecurity extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.fileTypes = [];
    this.fileFields = [];
  }
  connectedCallback() {
    super.connectedCallback();
    let requiredTypes = ['HTML', 'PDF']; // TODO: move this into the default config.json file
    getFileTypes().then(result => {
      let fileFields = result.filter(type => requiredTypes.includes(type.fileType)).map(type => {
        type.behavior = "HYBRID"; // always set to HYBRID
        return _objectSpread({
          safeToExec: type.securityRiskFileType === "false",
          // coerce to boolean
          classRisky: "risky-" + type.securityRiskFileType,
          classBehavior: "behavior-" + type.behavior,
          behaviorDownload: type.behavior === "DOWNLOAD",
          behaviorExecute: type.behavior === "EXECUTE_IN_BROWSER",
          behaviorHybrid: type.behavior === "HYBRID"
        }, type);
      });
      console.debug({
        fileFields
      });
      this.fileTypes = result;
      this.fileFields = fileFields;
    }).catch(error => {
      console.error(error);
    });
  }
  async saveYourself() {
    if (!this.dirty) {
      this.completed = true;
      return;
    }

    // Sending ALL types... send just the filtered list?
    await saveFileTypes(this.fileTypes).then(results => {
      this.completed = true;
      this.dirty = false;
      return results;
    }).catch(errors => {
      alert(errors);
      return errors;
    });
  }
  handleChange(evt) {
    let eleName = evt.target.name;
    let eleValue = evt.target.value;
    this.fileTypes = this.fileTypes.map(type => {
      if (type.fileType === eleName) {
        type.behavior = eleValue;
      }
      return type;
    });
    this.dirty = true;
  }
}
_registerDecorators(FileSecurity, {
  track: {
    fileTypes: 1,
    fileFields: 1
  }
})
export default _registerComponent(FileSecurity, {
  tmpl: _tmpl
});