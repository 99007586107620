import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./zpaperSolutions.html";
import zLightningElement from 'base/zLightningElement';
import { getInstalledPackages, deployAssets, activateFlexiPages } from 'data/salesforceConfigService';
import { getSolutions, updateDeployment, importTemplates, zippiPost, importDocSet } from 'data/zpaperConfigService';
class ZpaperSolutions extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.solutions = [];
    this.solutionId = '';
    this.isDeployed = false;
    this.packageList = [];
    this.deploymentRecord = {};
    this.requiredPackage = '';
    this.missingPackage = '';
    this.allowMissingPackage = false;
    this.solutionName = '';
    // isTransport = false;
    // keep track of which solutions have already been installed successfully
    this.solutionsOK = new Set();
  }
  connectedCallback() {
    super.connectedCallback();
    let verifyOrg = document.lightningWebComponents['verify-organization'];
    // let isTransport = verifyOrg && verifyOrg.isTransportOrg();
    let deployment = verifyOrg && verifyOrg.getDeployment();
    this.deploymentRecord = deployment;
    getSolutions().then(results => {
      //SHR210824 Verify that this is a HealthCloud-enabled org (for instance)
      getInstalledPackages().then(pkgList => {
        this.packageList = pkgList; // save this list for consent agreement updates later

        // If the web component specifies a solution by name or regex, filter the results
        if (this.solutionName) {
          //SHR240418: Support a regex to match multiple solution names
          let regex = new RegExp(this.solutionName.replace('\\', '\\\\')); // escape any "escapes"
          results = results.filter(solution => solution.name.includes(this.solutionName) || solution.name.match(regex));
          this.solutionId = results.length ? results[0].id : '';

          //SHR211027 check for this deployment's package prefix in the list of installed packages
          let prefix = (this.deploymentRecord.Package__c || '').replace('__', '');
          this.isDeployed = pkgList.some(pkg => pkg.namespacePrefix === prefix);

          //SHR211013 moved this check from verifyOrg to ensure the Security Settings are in place
          if (this.requiredPackage) {
            //SHR210824 Verify that this is a HealthCloud-enabled org (for instance)
            let found = pkgList.some(pkg => pkg.namespacePrefix === this.requiredPackage);
            if (!found) {
              this.missingPackage = this.requiredPackage;
            }
          }
        }

        // better than nothing -- sorted by name for now
        this.solutions = results.sort((s1, s2) => s1.name.localeCompare(s2.name)).map(solution => {
          // strip off any (text inside parens), and remove non-word chars
          solution.helpId = solution.name.replace(/\(.*\)/, '').replace(/\W/g, '');
          this.loadDescription(solution.id, solution.description);
          return solution;
        });
      }).catch(error => {
        console.error(error);
      });
    }).catch(error => {
      console.error(error);
    });
  }
  confirmDeployErrors(assets) {
    if (typeof assets === 'string' || typeof assets === 'undefined') {
      alert('Unexpected Error during deployment: ' + assets);
      return false;
    }
    let failures = assets.details && assets.details.componentFailures;
    // Ensure an array of failed components (SF returns an object if only 1 fails)
    failures = Array.isArray(failures) ? failures : [failures];
    failures = failures.map(fail => `[${fail.componentType}] ${fail.fullName}: ${fail.problem}`);
    let warnings = assets.numberComponentErrors ? `
Deployment stopped with ${assets.numberComponentErrors} errors:
    ${failures.join('\n    ')}
` : '';
    /* eslint-disable-next-line no-restricted-globals */
    return warnings && confirm(warnings + 'Continue anyway?');
  }
  openStatusWindow() {
    let sfServer = this.deploymentRecord.SF_Server__c; // Get the sfServer from the log-in information
    if (!sfServer.startsWith("https")) {
      sfServer = "https://" + sfServer;
    }
    console.log({
      sfServer
    });
    let aURL = sfServer + "/changemgmt/monitorDeployment.apexp";
    let w = window.open(aURL, 'zDeployStatus', 'resizable=1');
    w.focus();
  }
  async saveYourself() {
    if (this.missingPackage) {
      /* eslint-disable-next-line no-restricted-globals */
      if (!this.allowMissingPackage || !confirm(`The preferred Salesforce package ${this.missingPackage} should be installed in order to continue.
                Do you want to proceed anyway?`)) {
        return;
      }
    }

    // On the first installation, save the installed package list back to their deployment record
    if (!this.deploymentRecord.Notes__c && !!this.packageList && this.packageList.length) {
      //TODO: update existing Notes__c without over-writing other info, or endlessly appending this list
      let notes = {
        "InstalledPackages": this.packageList
      };
      let record = JSON.parse(JSON.stringify(this.deploymentRecord)); //SHR211018 clone the record
      record.Notes__c = JSON.stringify(notes, null, 2);

      // Async update of the package list in zProd (don't wait or stop on errors)
      updateDeployment(record);
      this.deploymentRecord = record;
    }

    // Continue to the next step, unless there is a problem
    this.completed = true;
    let isInstalled = this.solutionsOK.has(this.solutionId);
    let solution = this.solutions.filter(s => s.id === this.solutionId);

    // Do the actual installation, unless this solution was previously installed
    if (solution && solution.length === 1 && !isInstalled) {
      let target = this.template.querySelector(`ol[name="${this.solutionId}"]`);
      let hasDocSet = false;
      let hasFlexiPages = false;
      if (this.isDeployed) {
        /* eslint-disable-next-line no-restricted-globals */
        this.isDeployed = !confirm(`
This accelerator was previously installed -- Do you want to re-deploy?

            Press OK to redeploy,
            or Cancel to skip deployment
            and continue with the remaining steps.
                `);
      }

      // Clear out any previous status icons
      $(target).find('li').removeClass('step-loading');
      $(target).find('li').removeClass('step-success');
      $(target).find('li').removeClass('step-failure');
      if (this.isDeployed) {
        //SHR211028 skip re-deploying in orgs that can time-out
        $(target).find('li.install-assets').addClass('step-success');
      } else if (solution[0].package.assets) {
        $(target).find('li.install-assets').addClass('step-loading');
        let assets = await deployAssets(solution[0]).catch(err => {
          console.error(err);
          if (err.startsWith("502") || err.startsWith("504")) {
            //TODO: find a more definitive way to check for gateway timeout
            /* eslint-disable-next-line no-restricted-globals */
            if (confirm(`
This deployment seems to be taking a long time --
Do you want to visit the Deployment Status page?

            Press OK to be redirected,
            of Cancel to stay on this page.

(You will be able to skip re-deployment later, if necessary)
                            `)) {
              this.openStatusWindow();
            }
          }
        });
        if (assets.success && assets.details && !assets.numberComponentErrors || this.confirmDeployErrors(assets)) {
          $(target).find('li.install-assets').addClass(assets.success ? 'step-success' : 'step-warning');
          $(target).find('li.install-actions').addClass('step-loading');

          //SHR201006 Eric does NOT always want to import the actions, so use the ImportURL to do that
          // let actionResult = await importActions(assets);
          // if (actionResult && actionResult.every(ele => "success" === ele.status)) {
          //     $(target).find('li.install-actions').addClass('step-success');
          // }
          // else {
          //     $(target).find('li.install-actions').addClass('step-failure');
          //     alert('Error importing Actions:' + JSON.stringify(actionResult));
          //     this.completed = false;
          // }

          //SHR240621: If the deploy times out, the details will be null, so check first!
          if (assets.details && assets.details.componentSuccesses && assets.details.componentSuccesses.length) {
            // Check the completed assets to see if zDocSet_json was installed
            hasDocSet = assets.details.componentSuccesses.some(asset => asset.fullName === "zDocSetup_json");
            // Check the completed assets to see if any Page Layouts were installed
            hasFlexiPages = assets.details.componentSuccesses.some(asset => asset.componentType === "FlexiPage");
          }
        } else {
          $(target).find('li.install-assets').addClass('step-failure');
          console.warn('Failures:', assets.details && assets.details.componentFailures || assets);
          // alert(`Error in SF Assets: ${assets.numberComponentErrors} out of ${assets.numberComponentsTotal} could not be deployed!`);
          this.completed = false;
        }
      }

      // Do the importation of the zDocument Settings.
      if (this.completed) {
        if (hasDocSet) {
          // $(target).find('li.import-docset').addClass('step-loading');
          console.debug("Call import zDocSet");
          let docsetImportResult = await importDocSet();
          console.debug({
            docsetImportResult
          });
        }
        if (hasFlexiPages) {
          console.debug("Call activate flexiPages");
          let activateResult = await activateFlexiPages();
          console.debug({
            activateResult
          });
        }

        //SHR250205: try to import docset and activate layouts, only show errors and continue
        // if (docsetImportResult.status && "success" === docsetImportResult.status) {
        //     $(target).find('li.import-docset').addClass('step-success');
        // }
        // else {
        //     $(target).find('li.import-docset').addClass('step-failure');
        //     this.completed = false;
        // }
      }
      if (this.completed && solution[0].package.templates) {
        $(target).find('li.install-templates').addClass('step-loading');
        let templateResult = await importTemplates(solution[0].package.templates);
        if (templateResult && typeof templateResult === 'object' && templateResult.status === 'success' || typeof templateResult === 'string' && templateResult.includes("success")) {
          //SHR210409 bad json returned
          $(target).find('li.install-templates').addClass('step-success');
        } else {
          $(target).find('li.install-templates').addClass('step-failure');
          alert(`Error importing Templates: ${templateResult.message}`);
          this.completed = false;
        }
      }
      if (this.completed && solution[0].package.importUrl) {
        $(target).find('li.install-extras').addClass('step-loading');
        let importTasks = [];
        let importUrls = solution[0].package.importUrl.split(',');
        importUrls.forEach(importUrl => {
          let task = zippiPost(importUrl).then(json => {
            console.log({
              json
            });
            return json;
          }).catch(err => {
            console.error({
              err
            });
            throw err;
          });
          importTasks.push(task);
        });
        await Promise.all(importTasks).then(results => {
          console.log('ImportTask results:', results);
          let errors = results.filter(result => result.status !== 'success');
          if (errors.length === 0) {
            $(target).find('li.install-extras').addClass('step-success');
          } else {
            $(target).find('li.install-extras').addClass('step-failure');
            let mess = errors.map(error => error.message).join('\n');
            alert(`Error(s) in Post-install: ${mess}`);
            this.completed = false;
          }
        }).catch(err => {
          $(target).find('li.install-extras').addClass('step-failure');
          console.error(err);
        });
      }
      if (this.completed && this.solutionId) {
        // Successfully completed -- add this solution id to the OK list
        this.solutionsOK.add(this.solutionId);
      }
    }
    this.showConfigStep();
  }
  showConfigStep(evt) {
    // Shortcut to Config UI by clicking the next-step icon
    let solutionId = evt && evt.target && evt.target.dataset && evt.target.dataset.solutionId;
    if (solutionId) {
      // Trigger the nearest Next button
      let $nextBtn = $('div.list-group-item.open a[data-acc-btn-next]');
      if ($nextBtn.length) {
        let nextEvt = new CustomEvent('next-step', {
          bubbles: false,
          cancelable: true
        });
        $nextBtn[0].dispatchEvent(nextEvt);
      }
    } else if (this.completed && this.solutionId) {
      // If not trigger by a mouse event, use the last installed solution
      solutionId = this.solutionId;
    }
    let solution = this.solutions.filter(s => s.id === solutionId);
    let $compDiv = $('#config-comp');
    $compDiv.hide();
    let $page = $('#config-page');
    $page.hide();
    let $missing = $('#config-none');
    $missing.hide();
    let $frame = $('#config-frame');

    // If the solution was successful installed, open its Config UI frame, if that URL exists
    if (solution) {
      let configUrl = solution.length && solution[0].package && solution[0].package.configUrl || '';
      if (configUrl) {
        let configUrls = configUrl.split(','); //SHR211012 add support for multiple comma-separated urls
        for (let compUrl of configUrls) {
          let comp = compUrl.trim().match(/https?:\/\/.*\/ui\/config\/(\w+)-?(\w+)?/i);
          if (comp) {
            // Normalize the component name from "kebab-case" to "camelCase"
            let compName = comp[1] + (comp[2] ? comp[2].replace(/^([a-z])(.*)/, (_0, _1, _n) => _1.toUpperCase() + _n.toLowerCase()) : 'Config');
            let compCls = document.lightningWebComponents[compName];
            // Remove any previously added web component, if it's not the same type
            if (compCls && $compDiv.length && $compDiv[0].childElementCount && compCls.localName !== $compDiv[0].children[0].localName) {
              $compDiv[0].children[0].remove();
            }
            // Add the new web component, unless it already exists
            if (compCls && $compDiv.length && $compDiv[0].childElementCount === 0) {
              $compDiv[0].appendChild(compCls);
            }
            $compDiv.show();
          } else if ($frame.length) {
            $frame.attr("src", compUrl);
            $page.show();
          }
        }
      } else {
        $missing.show();
      }
    } else {
      $missing.show();
    }
  }
  loadDescription(solutionId, description) {
    let moreInfo = this.template.querySelector(`div[name="${solutionId}"] div.more-info`);
    $(moreInfo).html(description);
  }
  showDescription(evt) {
    let solution = this.solutions.filter(s => s.id === evt.target.dataset.solutionId);
    let target = this.template.querySelector(`div[name="${evt.target.dataset.solutionId}"] div.more-info`);
    if (solution && solution.length === 1) {
      $(target).html(solution[0].description);
      $(target).trigger("mouseover");
    }
  }
  openConfigUi(evt) {
    let solution = this.solutions.filter(s => s.id === evt.target.dataset.solutionId);
    if (solution && solution.length === 1) {
      window.open(solution[0].package.configUrl, 'configUi');
    }
  }
  handleClick(evt) {
    this.solutionId = evt.target.dataset.solutionId;
  }
}
_registerDecorators(ZpaperSolutions, {
  publicProps: {
    solutionName: {
      config: 0
    }
  },
  track: {
    solutions: 1,
    solutionId: 1,
    isDeployed: 1,
    packageList: 1,
    deploymentRecord: 1,
    requiredPackage: 1,
    missingPackage: 1,
    allowMissingPackage: 1
  },
  fields: ["solutionsOK"]
})
export default _registerComponent(ZpaperSolutions, {
  tmpl: _tmpl
});