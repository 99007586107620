import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./verifyOrganization.html";
import zLightningElement from 'base/zLightningElement';
//I'm trying to learn how to use this in LSC.OSS. For now just use alerts
// import {ShowToastEvent} from 'lightning/platformShowToastEvent';
import { getDeploymentRecord, updateDeployment } from 'data/zpaperConfigService';
// import { getInstalledPackages } from 'data/salesforceConfigService';

class VerifyOrganization extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.deploymentRecord = {};
    this.deploymentFields = [];
    this.deploymentFieldsLength = 0;
    this.deploymentExists = true;
    // trust now, verify later
    this.myDomainDeployed = true;
    this.matchedPackage = true;
    this.zPackage = "";
    this.sfServer = "";
    this.sfUsername = "";
    this.isInitialized = false;
    this.sfUsername = null;
    this.clientEmail = null;
    this.sfOrgId = null;
  }
  getDeployment() {
    return this.deploymentRecord;
  }
  setDeployment(deployment) {
    this.deploymentRecord = deployment;
  }
  getSfServer() {
    return this.sfServer;
  }
  getSfUsername() {
    return this.sfUsername;
  }
  isTransportOrg() {
    return this.zPackage === 'ZPAPER6__';
  }
  connectedCallback() {
    super.connectedCallback();
    let loginView = document.lightningWebComponents['login-view'];
    let userInfo = loginView && loginView.userInfo;
    if (!userInfo) {
      console.error('User is not logged in!');
      return;
    }

    // Fields from the logged in user's session, not deployment record
    this.sfUsername = userInfo.username;
    this.clientEmail = userInfo.email;
    this.sfOrgId = userInfo.organization_id;
    this.sfServer = userInfo.urls.custom_domain || userInfo.urls.rest;
    this.sfServer = this.sfServer.split(/[/]+/)[1];
    console.debug("this.sfServer =>", this.sfServer);
    if (!this.sfServer.split(".").includes("my")) {
      // Does the url contain .my. ?
      // Setting this to false will prevent the user from going further with the wizard until the window is reloaded.
      this.completed = false;
      this.myDomainDeployed = false;
    }

    // const evt = new ShowToastEvent({
    //     title: "This is the Title",
    //     message: "Hello World",
    //     variant: "error"
    // });
    // this.dispatchEvent(evt);
    getDeploymentRecord(this.sfOrgId).then(deployment => {
      this.isInitialized = true;
      if (deployment.message || deployment.errorMessage) {
        this.completed = false;
        this.deploymentExists = false;
        return;
      }

      //SHR250210: Partial updates to the Deployment record, for /initialize endpoint
      this.deploymentExists = true;
      this.deploymentRecord = deployment;
      this.deploymentRecord.Salesforce_Org_18_char__c = this.sfOrgId;
      this.deploymentRecord.SF_Server__c = this.sfServer;
      this.deploymentRecord.Primary_User_Login__c = this.sfUsername;

      //SHR250212: Ignore any invalid masterId < 10000
      if (!this.deploymentRecord.KBin_Master_ID__c > 10000) {
        this.deploymentRecord.KBin_Master_ID__c = "";
      }
      let verifyFields = [{
        key: "Name",
        label: "Deployment Name",
        value: deployment.Name
      }, {
        key: "Salesforce_Org_18_char__c",
        label: "Organization ID",
        value: this.sfOrgId
      }, {
        key: "SF_Server__c",
        label: "Salesforce Server",
        value: this.sfServer
      }, {
        key: "Primary_User_Login__c",
        label: "Salesforce Username",
        value: this.sfUsername
      }, {
        key: "Client_Email__c",
        label: "Email Address",
        value: this.clientEmail
      }];
      console.debug({
        verifyFields
      });
      this.deploymentFieldsLength = verifyFields.length;
      this.deploymentFields = verifyFields;
      this.zPackage = deployment.Package__c; //SHR211019 check the package prefix of the deployment record is correct...
      switch (this.zPackage) {
        case "ZPAPER__":
          this.matchedPackage = true; //SHR211019 no restrictions on installing along side the base packages
          break;
        case "ZPAPER6__":
          this.matchedPackage = loginView.packageName === "zPaper:Installer"; //SHR211019 package prefix must match this installer
          break;
        case "__ZMERGE":
          this.matchedPackage = loginView.packageName === "zPaper:Merge"; //SHR211019 package prefix must match this installer
          break;
        default:
          this.matchedPackage = true; //SHR211019 package prefix not set, so allow installs, I suppose?
          break;
      }
    }).catch(error => {
      console.error("Error received when retrieving Deployment record.");
      console.error(error);
      this.deploymentExists = false;
    });
  }
  async saveYourself() {
    if (!this.deploymentExists) {
      alert("You must follow the given instructions to get your Deployment record created before you can continue.");
      return;
    }
    if (!this.matchedPackage) {
      // eslint-disable-next-line no-restricted-globals
      let ok = confirm("Your configuration may not be compatible with all of available solutions listed below... \n" + "If you want to continue installing optional solutions, please press the 'OK' button -- otherwise press 'Cancel'");
      if (!ok) {
        return;
      }
    }
    if (!this.myDomainDeployed) {
      alert("You must follow the given instructions to deploy your custom Salesforce domain before you can continue. " + "If you have successfully deployed your custom domain, please click the 'Reload' button to reload this " + "wizard and continue with configuration of the zPaper application.");
      return;
    }

    //SHR250210: Partial updates to the Deployment record, for /initialize endpoint
    try {
      await updateDeployment(this.deploymentRecord);
      console.info(`Updated Deployment: SF_Server__c = ${this.deploymentRecord.Salesforce_Org_18_char__c}`);
    } catch (error) {
      console.warn("Unable to update Deployment Record!", error);
    }
    this.completed = true; // move along to next tab
  }

  /* eslint-disable-next-line no-unused-vars */
  handleMyDomainClick(evt) {
    let w = window.open("https://" + this.sfServer + "/lightning/setup/OrgDomain/home", 'setMyDomain', 'resizable=1');
    w.focus();
  }

  /* eslint-disable-next-line no-unused-vars */
  handleReloadClick(evt) {
    window.location.reload();
  }
}
_registerDecorators(VerifyOrganization, {
  publicMethods: ["getDeployment", "setDeployment", "getSfServer", "getSfUsername", "isTransportOrg"],
  track: {
    deploymentRecord: 1,
    deploymentFields: 1,
    deploymentFieldsLength: 1,
    deploymentExists: 1,
    myDomainDeployed: 1,
    matchedPackage: 1,
    zPackage: 1,
    sfServer: 1,
    sfUsername: 1,
    isInitialized: 1
  },
  fields: ["clientEmail", "sfOrgId"]
})
export default _registerComponent(VerifyOrganization, {
  tmpl: _tmpl
});