import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./channelsConfig.html";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import zLightningElement from 'base/zLightningElement';
import { importTemplates, getSubGroups, clearRedisCache } from 'data/zpaperConfigService';
import { getCustomSettings, saveCustomSettings, getGroupQueues, getObjectTypes, getRecordTypes, getPicklist } from 'data/salesforceConfigService';
class ChannelsConfig extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.deploymentRecord = {};
    this.editChannel = {};
    this.channelList = [];
    this.faxNumbers = [];
    this.sfObjectTypes = [];
    this.sfRecordTypes = [];
    this.sfClassifications = [];
    this.sfPrograms = [];
    this.zpGroups = [];
    this.sfQueues = [];
    this.objTypes = [];
    this.recTypes = [];
    this.channelNo = 0;
    this.buttonTitle = 'Select Channel...';
    this.userId = "";
    this.userName = "";
    // internals
    this.settingsMap = {};
    this.prefixList = [''];
    //SHR211026 keep track of custom setting field names, as some have a MP prefix
    //SHR250317: this value depends on the createObject checkbox
    this.action = "";
    // these are parsed from the channel path string:
    this.classification = "";
    this.program = "";
  }
  get clientAction() {
    return this.action;
  }
  get path() {
    return this.editChannel && this.editChannel.path || '';
  }
  get faxnum() {
    return this.editChannel && this.editChannel.fax || '';
  }
  get scan() {
    return this.editChannel && this.editChannel.scan || '';
  }
  get bucket() {
    return this.editChannel && this.editChannel.bucket || '';
  }
  get upload() {
    return this.editChannel && this.editChannel.upload || '';
  }
  connectedCallback() {
    super.connectedCallback();
    let loginView = document.lightningWebComponents['login-view'];
    let oauthLogin = document.lightningWebComponents['oauth-login'];
    const userInfo = loginView && loginView.userInfo() || oauthLogin && oauthLogin.userInfo();
    if (!userInfo) {
      console.error('User is not logged in!');
      return;
    }
    this.userId = userInfo.user_id;
    this.userName = userInfo.nick_name;
    let verifyOrg = document.lightningWebComponents['verify-organization'];
    this.deploymentRecord = verifyOrg && verifyOrg.getDeployment();
    if (!this.deploymentRecord) {
      console.error('Deployment Record is missing for this Org!');
      return;
    }
    this.faxNumbers = this.deploymentRecord.zPaper_Fax__c.split(',').filter(fax => fax);
    if (this.faxNumbers.length === 0 || this.faxNumbers[0] !== '') {
      this.faxNumbers.unshift(''); // ensure there is an empty slot at the start of the list
    }
    getCustomSettings().then(settings => {
      // preserve the Custom Settings object as a flat object that contains name/value pairs
      Object.keys(settings).forEach(key => {
        this.settingsMap[key] = settings[key].value;
      });
      let channels = [{
        id: 0,
        json: '',
        // the original json string from custom settings
        name: 'zChannel0__c',
        label: '',
        value: '',
        // the current json string of the channel object
        settings: {
          path: ''
        }
      }];

      // Flatten/Filter the map into an array that can be rendered in the template
      const prefix = this.deploymentRecord.Package__c || '';
      const regex = new RegExp(`^(?<pfx>${prefix})?zChannel(?<num>\\d+)__c`);
      for (let key in settings) {
        if (!Object.prototype.hasOwnProperty.call(settings, key)) {
          continue;
        }
        let grp = regex.exec(key);
        if (!grp) {
          continue;
        }
        this.prefixList[+grp.groups.num] = grp.groups.pfx || ''; //SHR211026 save custom setting prefix

        let val = this.settingsMap[key];
        let def = _objectSpread({}, settings[key]); // clone the oringinal settings obj
        try {
          def.settings = val ? JSON.parse(val) : {};
        } catch (err) {
          console.warn(`Expression (type: ${typeof val}, length = ${val.length}) is not valid JSON!`, val);
          try {
            if (val.includes("'") && !val.includes('"')) {
              console.info('Convert to double quotes...');
              val = val.replace(/'/g, '"');
              def.settings = JSON.parse(val);
            }
          } catch (err2) {
            if (val.trim().startsWith('{') && val.trim().endsWith('}')) {
              console.info('Last resort: trying eval...');
              try {
                /* eslint-disable-next-line no-eval */
                def.settings = eval("() => {return " + val + ";}")();
              } catch (err3) {
                console.warn(key, 'is not readable! skipping it...');
                def.settings = {};
              }
            }
          }
        }

        // If we have a valid channel def, add it to the array
        let tmp = def && def.label && def.label.match(/^zChannel(\d+)/);
        let valid = val && Object.keys(def.settings).length > 0;
        if (valid) {
          this.settingsMap[key] = JSON.stringify(def.settings);
        }
        if (tmp && def.settings) {
          channels[+tmp[1]] = _objectSpread({
            id: +tmp[1],
            class: valid ? "valid" : "invalid",
            json: val
          }, def);
        }
      }
      console.info('Channels =>', channels);
      this.channelList = channels;
      this.loadEditFields(0);
    }).catch(error => {
      console.error(error);
    });
    getSubGroups(this.deploymentRecord.KBin_Master_ID__c).then(groups => {
      this.zpGroups = (groups || []).filter(g => {
        // Filter out any Zen* and Sprkz* toolbar groups
        return !g.name.toLowerCase().startsWith("zen") && !g.name.toLowerCase().startsWith("sprkz");
      });
    }).catch(error => {
      console.error(error);
    });
    getGroupQueues().then(queues => {
      this.sfQueues = queues || [];
    }).catch(error => {
      console.error(error);
    });
    getObjectTypes().then(types => {
      this.sfObjectTypes = types || [];
      // Remove the internal ZPAPER__* types from the list
      this.objTypes = this.sfObjectTypes.map(type => type.name).filter(name => !name.startsWith('ZPAPER'));
    }).catch(error => {
      console.error(error);
    });
    getRecordTypes().then(types => {
      this.sfRecordTypes = types || [];
    }).catch(error => {
      console.error(error);
    });

    //SHR250328: locate the zStack object and use its apiname prefix!
    const zStack = this.sfObjectTypes.find(type => type.label === 'zStack');
    if (zStack) {
      console.debug({
        zStack
      });
      const prefix = zStack.name.replace("zStack__c", ""); // optional package prefix, so this may be empty

      // getPicklist("ZPAPER__zStack__c", "ZPAPER__Classification__c")
      getPicklist(zStack.name, prefix + "Classification__c").then(picklist => {
        this.sfClassifications = picklist || [];
      }).catch(error => {
        console.error(error);
      });

      // getPicklist("ZPAPER__zStack__c", "ZPAPER__Program__c")
      getPicklist(zStack.name, prefix + "Program__c").then(picklist => {
        this.sfPrograms = picklist || [];
      }).catch(error => {
        console.error(error);
      });
    }
  }
  importTemplates(evt) {
    let zipUrl = evt.target.previousElementSibling.value;
    if (zipUrl && this.apiKey) {
      importTemplates(zipUrl, this.apiKey);
    }
  }
  changeObjectType(evt) {
    let objType = evt.target.value;
    this.selectObjectType(objType);
  }
  selectObjectType(objType) {
    let recTypes = this.sfRecordTypes.filter(type => type.SobjectType === objType);
    if (objType) {
      console.log(objType, 'record types =>', recTypes);
    }
    this.recTypes = recTypes;
  }
  changeRecordType(evt) {
    let recType = evt.target.value;
    this.selectRecordType(recType);
  }
  selectRecordType(recType) {
    if (recType) {
      console.log('set record type =', recType);
    }
  }
  nextAvailable() {
    let nextNo = this.channelList.reduce((nxt, chan, idx) => {
      return !chan.value && idx > 0 && idx < nxt ? idx : nxt;
    }, this.channelList.length);
    console.log('Next available channel =', nextNo);
    return nextNo;
  }
  channelLabel(idx) {
    let channelNo = idx || this.nextAvailable;
    let label = `zChannel${channelNo}`;
    return label;
  }
  changeChannel(evt) {
    let channelNo = evt.target.value;
    console.log('Switch to channel #', channelNo);
    this.loadEditFields(channelNo);
  }
  updateChannel() {
    console.log('Update channel #', this.channelNo);
    let channelDef = this.readEditFields();
    this.channelList[this.channelNo].settings = channelDef;
    let jsonDef = JSON.stringify(channelDef);
    if (jsonDef.length > 255) {
      console.warn('Channel Definition is TOO LONG to be saved in Salesforce!');
      console.debug({
        channelDef
      });
    }
    this.channelList[this.channelNo].value = jsonDef;
    this.loadEditFields(this.channelNo);
    this.dirty = true;
    this.completed = false;
  }
  insertChannel() {
    let newChannels = [...this.channelList]; // we need a copy to trigger a DOM update
    let nextNo = this.nextAvailable();
    if (nextNo === this.channelList.length) {
      /* eslint-disable-next-line no-restricted-globals */
      if (!confirm(`Please add a new 'zChannel${nextNo}__c' textarea field to your custom settings... then select 'Ok'`)) {
        return;
      }
    }
    console.log('Insert channel #', nextNo);
    let channelDef = this.readEditFields();
    let jsonDef = JSON.stringify(channelDef);
    if (jsonDef.length > 255) {
      console.warn('Channel Definition is TOO LONG to be saved in Salesforce!');
      console.debug({
        channelDef
      });
    }
    let pfx = this.prefixList[nextNo] || ''; //SHR211026 lookup custom setting prefix
    newChannels[nextNo] = {
      id: nextNo,
      json: '',
      name: `${pfx}zChannel${nextNo}__c`,
      label: `zChannel${nextNo}`,
      value: jsonDef,
      settings: channelDef
    };
    this.channelList = newChannels;
    this.dirty = true;
    this.completed = false;

    /* eslint-disable-next-line @lwc/lwc/no-async-operation */
    setTimeout(() => {
      let radio = this.template.querySelectorAll('input[name=activeChannel]')[nextNo - 1];
      if (radio) {
        radio.click(); // try to select the newly added channel?
      }
    }, 100);
  }
  revertChannel(evt) {
    let channelNo = evt.target.dataset.channelId;
    console.log('Revert channel #', channelNo);
    this.channelList[channelNo].settings = JSON.parse(this.channelList[channelNo].json || '{}');
    this.channelList[channelNo].value = this.channelList[channelNo].json || '';
    this.loadEditFields(channelNo);
  }
  removeChannel(evt) {
    let channelNo = evt.target.dataset.channelId;
    console.log('Remove channel #', channelNo);
    this.channelList[channelNo].settings = {};
    this.channelList[channelNo].value = '';
    this.loadEditFields(channelNo);
    this.dirty = true;
    this.completed = false;
  }

  /* eslint-disable-next-line no-unused-vars */
  updatePath(evt) {
    let $form = $(this.template.querySelector('form'));
    let $path = $form.find('input[name="zChannelPath"]');
    let cCls = $form.find('select[name="ClassifyName"]').val();
    let cPgm = $form.find('select[name="ProgramName"]').val();
    let cName = $form.find('input[name="ChannelName"]').val();
    $path.val(`${cCls}/${cPgm}/${cName}`);
    this.classification = cCls || "";
    this.program = cPgm || "";
  }
  loadEditFields(channelNo) {
    if (!channelNo) {
      // reset editor fields
      this.channelNo = 0;
      this.buttonTitle = 'Select Channel...';
      return;
    }
    if (channelNo < 1 || channelNo > this.channelList.length) {
      console.warn(`Channel #${channelNo} not found!`);
      this.buttonTitle = 'Select Channel...';
      return;
    }
    this.channelNo = channelNo;
    this.editChannel = this.channelList[channelNo].settings;
    this.buttonTitle = `Update Channel #${this.channelNo}`;
    let form = this.template.querySelector('form');
    let $form = $(form);
    let $chan = $form.find('input[name="activeChannel"]');
    // $chan.val(channelNo);
    if (channelNo <= $chan.length) {
      $chan[channelNo - 1].click();
    }

    // Pull the fax number from whatever field starts with that number
    let faxNo = this.editChannel && this.editChannel.fax || this.editChannel.email && this.editChannel.email.replace(/^(1?\d{10}).*@.*$/, '$1') || this.editChannel.secure && this.editChannel.secure.replace(/^(1?\d{10}).*@.*$/, '$1');

    //SHR210212 users and readers are ':' separated -- handle old data with ',' separators too
    let grpIds = this.editChannel['db-users'] ? this.editChannel['db-users'].split(/[:,]/) : [$form.find('div.edit-box select[name="sharedGroups"] option')[0].value];
    $form.find('div.edit-box select[name="initialOwner"]').val(this.editChannel.owner);
    $form.find('div.edit-box select[name="sharedGroups"]').val(grpIds);

    // Update any derived reactive fields
    let create = this.editChannel.create || '';
    let tester = create.match(/(\w+):?(.*)/);
    let type1 = tester ? tester[1] : '';
    let type2 = tester ? tester[2] : '';
    $form.find('div.edit-box select[name="objectType"]').val(type1);
    this.selectObjectType(type1);
    /* eslint-disable-next-line @lwc/lwc/no-async-operation */
    setTimeout(() => {
      $form.find('div.edit-box select[name="recordType"]').val(type2);
      this.selectRecordType(type2);
    }, 10);
    let outputs = (this.editChannel.deliver || '').split(',').map(op => `${op}Out`);
    $form.find(`div.edit-box :checkbox[name$="Out"]`).prop('checked', function () {
      return outputs.includes(this.name);
    });

    // Manually update the checkboxes to match the settings (for now)
    let cPath = this.editChannel.path || '';
    let [cCls, cPgm, cName] = cPath.split('/');
    $form.find('select[name="ClassifyName"]').val(cCls || '');
    $form.find('select[name="ProgramName"]').val(cPgm || '');
    $form.find('input[name="ChannelName"]').val(cName || '');
    $form.find('input[name="zChannelPath"]').val(cPath);
    $form.find('textarea[name="work"]').val(JSON.stringify($chan.settings));
    $form.find('div.edit-box :checkbox[name="faxIn"]').prop('checked', this.editChannel.fax || false);
    $form.find('div.edit-box select[name="faxNo"]').val(faxNo || '');
    $form.find('div.edit-box :checkbox[name="emailIn"]').prop('checked', this.editChannel.email || false);
    $form.find('div.edit-box :checkbox[name="secureIn"]').prop('checked', this.editChannel.secure || false);
    $form.find('div.edit-box :checkbox[name="scanIn"]').prop('checked', this.editChannel.scan || false);
    $form.find('div.edit-box input[name="scanDir"]').val(this.editChannel.scan || '');
    $form.find('div.edit-box :checkbox[name="s3In"]').prop('checked', this.editChannel.s3 || false);
    $form.find('div.edit-box input[name="s3Bucket"]').val(this.editChannel.s3 || '');
    $form.find('div.edit-box :checkbox[name="sftpIn"]').prop('checked', this.editChannel.sftp || false);
    $form.find('div.edit-box :checkbox[name="uploadIn"]').prop('checked', this.editChannel.upload || false);
    $form.find('div.edit-box :checkbox[name="createType"]').prop('checked', this.editChannel.create || false);
    $form.find('div.edit-box input[name="clientAction"]').val(this.editChannel.action || '');
  }
  readEditFields() {
    // Retrieve the values from the checkboxes and build a channel object
    let form = this.template.querySelector('form');
    let $form = $(form);
    let grpId = this.deploymentRecord.KBin_Master_ID__c;
    let cPath = $form.find('input[name="zChannelPath"]').val() || '';
    let faxNo = $form.find('div.edit-box select[name="faxNo"]').val();
    let fldId = $form.find('div.edit-box input[name="scanDir"]').val();
    let bktId = $form.find('div.edit-box input[name="s3Bucket"]').val();
    let dbIds = $form.find('div.edit-box select[name="sharedGroups"]').val() || [grpId];
    let oType = $form.find('div.edit-box select[name="objectType"]').val() || '';
    let rType = $form.find('div.edit-box select[name="recordType"]').val() || '';
    /* eslint-disable-next-line no-undef-init */
    let creds = undefined; // TODO: gather this info and save it
    // let $outs = $form.find('div.edit-box :checkbox[name$="Out"]:checked');
    this.action = $form.find('div.edit-box :checkbox[name="createType"]').prop('checked') && oType ? `client${oType}${rType}()` : '';

    /* eslint-disable-next-line no-unused-vars */
    let [cCls, cPgm, cName] = cPath.split('/').filter(c => c != null);
    // let faxnum = !cName || faxNo.includes(cName) ? faxNo : `${faxNo}+${cName}`;
    this.classification = cCls || "";
    this.program = cPgm || "";
    let channel = {
      'path': cPath,
      'fax': $form.find('div.edit-box :checkbox[name="faxIn"]').prop('checked') ? faxNo : undefined,
      'email': $form.find('div.edit-box :checkbox[name="emailIn"]').prop('checked') && faxNo ? `${faxNo}@mfb.zpaper.com` : undefined,
      'secure': $form.find('div.edit-box :checkbox[name="secureIn"]').prop('checked') && faxNo ? `${faxNo}@zpaper.direct.kno2fy.com` : undefined,
      'scan': $form.find('div.edit-box :checkbox[name="scanIn"]').prop('checked') ? fldId : undefined,
      's3': $form.find('div.edit-box :checkbox[name="s3In"]').prop('checked') ? bktId : undefined,
      'sftp': $form.find('div.edit-box :checkbox[name="sftpIn"]').prop('checked') ? creds : undefined,
      'upload': $form.find('div.edit-box :checkbox[name="uploadIn"]').prop('checked') ? grpId : undefined,
      'action': $form.find('div.edit-box input[name="clientAction"]').val() || "",
      'create': $form.find('div.edit-box :checkbox[name="createType"]').prop('checked') && oType ? rType ? `${oType}:${rType}` : oType : undefined,
      'owner': $form.find('div.edit-box select[name="initialOwner"]').val() || this.userId,
      // 'deliver': $.map($outs, out => out.name.replace(/Out$/, '')).join(','),
      'db-users': ':' + dbIds.join(':') + ':',
      'db-readers': ':' + dbIds.join(':') + ':'
    };
    return channel;
  }

  // I cannot seem to trigger this function by updating the json string in the textarea...
  fillChecks(evt) {
    // console.log(evt.target);
    let $currRow = $(evt.target).closest('tr');
    let $prevRow = $currRow.prev('tr');
    let channelDef = JSON.parse(evt.target.value || '{}');
    $prevRow.find(':checkbox').each(function () {
      this.checked = channelDef[this.name] || false;
    });
  }
  async saveYourself() {
    if (!this.dirty) {
      this.completed = true;
      return;
    }
    this.handleSave();
  }
  handleSave(evt) {
    let form = evt && evt.target && evt.target.form || this.template.form;
    if (form) {
      this.updatedSettings = {
        Id: this.settingsMap.Id
      };
      for (let cno = 1; cno < this.channelList.length; cno++) {
        let chan = this.channelList[cno];
        this.updatedSettings[chan.name] = chan.value || '';
      }
      this.dirty = true;
      saveCustomSettings(this.updatedSettings).then(response => {
        if (response && response.errorCode) {
          throw new Error(response.errorCode);
        }
        if (response && response.errors && response.errors.length) {
          throw new Error(response.errors.join('\n'));
        }
        console.info({
          customSettings: response
        });
        this.updatedSettings = {};
        this.dirty = false;
        this.completed = true;
        clearRedisCache().then(reset => {
          if (reset.status !== 'success') {
            throw new Error(JSON.stringify(reset));
          }
        }).catch(error => {
          console.warn("Custom Settings saved, but the Redis cache was not reset!");
          console.error(error);
        });
      }).catch(errors => {
        console.error(errors);
        alert(`Error(s) in saving Channel Settings:\n ${errors}`);
      });
    }
  }
}
_registerDecorators(ChannelsConfig, {
  track: {
    deploymentRecord: 1,
    editChannel: 1,
    channelList: 1,
    faxNumbers: 1,
    sfObjectTypes: 1,
    sfRecordTypes: 1,
    sfClassifications: 1,
    sfPrograms: 1,
    zpGroups: 1,
    sfQueues: 1,
    objTypes: 1,
    recTypes: 1,
    channelNo: 1,
    buttonTitle: 1,
    userId: 1,
    userName: 1
  },
  fields: ["settingsMap", "prefixList", "action", "classification", "program"]
})
export default _registerComponent(ChannelsConfig, {
  tmpl: _tmpl
});