import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./loginView.html";
import zLightningElement from 'base/zLightningElement';
import { getLoggedInUser } from 'data/authService';
class LoginView extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.nextPage = "/";
    this.isSandbox = false;
    this.marquee = "";
    this.packageName = "";
  }
  get sfServer() {
    return `${this.isSandbox ? 'test' : 'login'}.salesforce.com`;
  }
  get loginUrl() {
    return `/oauth/login?sfLoginType=${this.isSandbox ? 'test' : 'login'}&nextPage=${this.nextPage}`;
  }
  connectedCallback() {
    super.connectedCallback();
    console.debug('@@@@ Pulling logged-in user info @@@@');
    getLoggedInUser().then(info => {
      if (info.user_id === undefined) {
        console.debug("User is not logged in yet.");
        this.loggedUser = undefined;
        this.nextPage = window.location.pathname;
        return;
      }
      console.debug({
        userInfo: info
      });
      this.loggedUser = info;

      // Now that we are logged in, show the accordion UI
      let showEvt = new CustomEvent('show-yourself', {
        bubbles: true,
        cancelable: true
      });
      $("#wizard")[0].dispatchEvent(showEvt);
    }).catch(errors => {
      console.warn(errors.statusText || "invalid session?");
    });
  }
  useSandbox(evt) {
    this.isSandbox = evt.target.value || false;
  }
}
_registerDecorators(LoginView, {
  publicProps: {
    marquee: {
      config: 0
    },
    packageName: {
      config: 0
    },
    sfServer: {
      config: 1
    },
    loginUrl: {
      config: 1
    }
  },
  track: {
    nextPage: 1,
    isSandbox: 1
  }
})
export default _registerComponent(LoginView, {
  tmpl: _tmpl
});