
import { createElement } from 'lwc';

import LoginView from "./modules/m2o/loginView/loginView";
import ConsentAgreement from "./modules/m2o/consentAgreement/consentAgreement";
import VerifyOrganization from "./modules/m2o/verifyOrganization/verifyOrganization";
import RegisterOrganization from "./modules/m2o/registerOrganization/registerOrganization";
import ZPaperSolutions from "./modules/m2o/zpaperSolutions/zpaperSolutions";
import NetworkAccess from "./modules/m2o/networkAccess/networkAccess";
import RemoteSites from "./modules/m2o/remoteSites/remoteSites";
import TrustedSites from "./modules/m2o/trustedSites/trustedSites";
import FileSecurity from "./modules/m2o/fileSecurity/fileSecurity";
import ConnectedApp from "./modules/m2o/connectedApp/connectedApp";
import CustomSettings from "./modules/m2o/customSettings/customSettings";
import MasterGroup from "./modules/m2o/masterGroup/masterGroup";
import AutodriveConfig from "./modules/m2o/autodriveConfig/autodriveConfig";
import ChannelsConfig from "./modules/m2o/channelsConfig/channelsConfig";
import ChannelAssignments from "./modules/m2o/channelAssignments/channelAssignments";

const loginView = createElement('login-view', { is: LoginView });
const consentAgreement = createElement('consent-agreement', { is: ConsentAgreement });
const verifyOrganization = createElement('verify-organization', { is: VerifyOrganization });
const registerOrganization = createElement('register-organization', { is: RegisterOrganization });
const zpaperSolutions = createElement('zpaper-solutions', { is: ZPaperSolutions });
const networkAccess = createElement('network-access', { is: NetworkAccess });
const remoteSites = createElement('remote-sites', { is: RemoteSites });
const trustedSites = createElement('trusted-sites', { is: TrustedSites });
const fileSecurity = createElement('file-security', { is: FileSecurity });
const connectedApp = createElement('connected-app', { is: ConnectedApp });
const customSettings = createElement('custom-settings', { is: CustomSettings });
const masterGroup = createElement('master-group', { is: MasterGroup });
const autodriveConfig = createElement('autodrive-config', { is: AutodriveConfig });
const channelsConfig = createElement('channels-config', { is: ChannelsConfig });
const channelAssignments = createElement('channel-assignments', { is: ChannelAssignments });


document.lightningWebComponents = {
    loginView,
    consentAgreement,
    verifyOrganization,
    registerOrganization,
    zpaperSolutions,
    networkAccess,
    remoteSites,
    trustedSites,
    fileSecurity,
    connectedApp,
    customSettings,
    masterGroup,
    autodriveConfig,
    channelsConfig,
    channelAssignments,
};

//SHR240403: also index by component id (to avoid document.querySelector lookups)
let compKeys = Object.keys(document.lightningWebComponents);
/* eslint-disable-next-line guard-for-in */
for (let compName of compKeys) {
    let compCls = document.lightningWebComponents[compName];
    let compId = compCls.tagName.toLowerCase();
    document.lightningWebComponents[compId] = compCls;
}

const loginDiv = $('#login')[0];
loginView.marquee = loginDiv.dataset && loginDiv.dataset.marquee || 'Welcome!';
loginView.packageName = loginDiv.dataset && loginDiv.dataset.packageName || 'zPaper:Enabler';
loginDiv.appendChild(loginView);
