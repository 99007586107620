import _implicitStylesheets from "./oauthLogin.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, d: api_dynamic, h: api_element} = $api;
  return [api_element("header", {
    key: 0
  }, [api_element("h2", {
    key: 1
  }, [api_text(" "), api_dynamic($cmp.marquee), api_text(" ")])]), api_element("main", {
    classMap: {
      "content": true
    },
    key: 2
  }, [!$cmp.loggedUser ? api_element("div", {
    classMap: {
      "login-wrapper": true
    },
    key: 3
  }, [api_element("div", {
    classMap: {
      "login": true
    },
    key: 4
  }, [api_element("h2", {
    key: 5
  }, [api_text("Welcome, please"), api_element("a", {
    classMap: {
      "brand-button": true
    },
    attrs: {
      "href": $cmp.loginUrl,
      "target": "_top"
    },
    key: 6
  }, [api_text("Log In")])])])]) : null])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_oauthLogin-host",
  shadowAttribute: "my-modules-m2o_oauthLogin"
};
