import { registerDecorators as _registerDecorators, registerComponent as _registerComponent, LightningElement } from "lwc";
import _tmpl from "./zLightningElement.html";
class zLightningElement extends LightningElement {
  isConnected() {
    return this.connected;
  }
  isCompleted() {
    return this.completed;
  }
  isSkipped() {
    return this.skipped;
  }
  isVisible() {
    return this.visible;
  }
  isDirty() {
    return this.dirty;
  }
  constructor() {
    super();

    // Add Help.js stylesheet
    this.connected = false;
    this.completed = false;
    this.skipped = false;
    this.visible = false;
    this.dirty = false;
    this.helpName = "";
    const helpStyle = document.createElement('link');
    helpStyle.rel = 'stylesheet';
    helpStyle.href = '../styles/help.css';
    this.template.appendChild(helpStyle);

    // Add SLDS CSS
    // let _sldsPath = '../SLDS/styles';
    // _sldsPath += '/salesforce-lightning-design-system.css';
    // const styles = document.createElement('link');
    // styles.href = _sldsPath;
    // styles.rel = 'stylesheet';
    // this.template.appendChild(styles);
  }
  connectedCallback() {
    this.activateHelpIcons();
    this.connected = true;
  }
  activateHelpIcons() {
    let helpName = this.helpName || this.template.host.localName;
    // check to see if help.js is available
    if (typeof activateHelp === 'function') {
      /* eslint-disable-next-line no-undef */
      activateHelp(helpName, this.template);
    }
  }
  showYourself() {
    // build any dom structure here (singleton)

    this.visible = true;
  }
  hideYourself() {
    this.visible = false;
  }
  async saveYourself() {
    // insert your save logic here...

    this.completed = true;
  }
}
_registerDecorators(zLightningElement, {
  publicProps: {
    helpName: {
      config: 0
    }
  },
  publicMethods: ["isConnected", "isCompleted", "isSkipped", "isVisible", "isDirty", "activateHelpIcons", "showYourself", "hideYourself", "saveYourself"],
  track: {
    connected: 1,
    completed: 1,
    skipped: 1,
    visible: 1,
    dirty: 1
  }
})
export default _registerComponent(zLightningElement, {
  tmpl: _tmpl
});