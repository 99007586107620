import _implicitStylesheets from "./channelAssignments.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, h: api_element, gid: api_scoped_id, k: api_key, d: api_dynamic, b: api_bind, i: api_iterator, f: api_flatten} = $api;
  const {_m0, _m1} = $ctx;
  return [api_element("h3", {
    key: 0
  }, [api_text("Channel Assignments")]), api_element("form", {
    attrs: {
      "id": api_scoped_id("config-form")
    },
    key: 1
  }, [api_element("table", {
    key: 2
  }, [api_element("tbody", {
    key: 3
  }, api_flatten([api_element("tr", {
    key: 4
  }, [api_element("th", {
    key: 5
  }, [api_text("Incoming Fax Channel")]), api_element("th", {
    key: 6
  }, [api_text("Assigned to Owner/Queue")])]), api_iterator($cmp.channelList, function (channel) {
    return [channel.id ? api_element("tr", {
      key: api_key(7, channel.name)
    }, [api_element("td", {
      key: 8
    }, [api_element("h4", {
      styleMap: {
        "display": "inline"
      },
      key: 9
    }, [api_dynamic(channel.label)]), api_element("input", {
      classMap: {},
      attrs: {
        "type": "text",
        "name": channel.name,
        "data-channel-id": channel.id,
        "readonly": ""
      },
      props: {
        "value": channel.settings.fax
      },
      key: 10
    }, [])]), api_element("td", {
      classMap: {
        "option-box": true
      },
      key: 11
    }, [$cmp.channelSettingExists ? api_element("select", {
      classMap: {
        "select-checkbox": true
      },
      attrs: {
        "name": "initialOwner",
        "data-channel-id": channel.id,
        "value": channel.settings.owner
      },
      key: 12,
      on: {
        "click": _m0 || ($ctx._m0 = api_bind($cmp.handleSelect))
      }
    }, api_flatten([api_element("option", {
      attrs: {
        "value": $cmp.userInfo.user_id
      },
      key: api_key(13, $cmp.userInfo.user_id)
    }, [api_dynamic($cmp.userInfo.nick_name)]), api_iterator($cmp.sfQueues, function (queue) {
      return api_element("option", {
        attrs: {
          "value": queue.Id
        },
        key: api_key(14, queue.Id)
      }, [api_dynamic(queue.Name)]);
    })])) : null, !$cmp.channelSettingExists ? api_element("i", {
      key: 15
    }, [api_text("Custom Setting: "), api_dynamic(channel.name), api_text(" does not exist...")]) : null])]) : null, channel.id ? api_element("tr", {
      key: api_key(16, channel.name)
    }, [api_element("td", {
      attrs: {
        "colspan": "2"
      },
      key: 17
    }, [api_element("textarea", {
      attrs: {
        "name": "json",
        "readonly": ""
      },
      key: 18
    }, [api_dynamic(channel.value)])])]) : null];
  })]))]), $cmp.dirty ? api_element("input", {
    styleMap: {
      "float": "right",
      "margin": "10px"
    },
    attrs: {
      "type": "button"
    },
    props: {
      "value": "Save  Custom Settings"
    },
    key: 19,
    on: {
      "click": _m1 || ($ctx._m1 = api_bind($cmp.handleSave))
    }
  }, []) : null, !$cmp.dirty ? api_element("input", {
    styleMap: {
      "float": "right",
      "margin": "10px"
    },
    attrs: {
      "type": "button",
      "readonly": "",
      "disabled": ""
    },
    props: {
      "value": "Saved Custom Settings"
    },
    key: 20
  }, []) : null])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_channelAssignments-host",
  shadowAttribute: "my-modules-m2o_channelAssignments"
};
