import _implicitStylesheets from "./channelsConfig.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, h: api_element, gid: api_scoped_id, b: api_bind, d: api_dynamic, k: api_key, i: api_iterator, f: api_flatten} = $api;
  const {_m0, _m1, _m2, _m3, _m4, _m5, _m6, _m7, _m8, _m9, _m10, _m11, _m12} = $ctx;
  return [api_element("h3", {
    key: 0
  }, [api_text("Channel Configuration")]), api_element("form", {
    attrs: {
      "id": api_scoped_id("config-form")
    },
    key: 1
  }, [api_element("div", {
    classMap: {
      "edit-box": true
    },
    key: 2
  }, [api_element("ul", {
    styleMap: {
      "width": "90%",
      "margin": "auto"
    },
    key: 3
  }, [api_element("li", {
    styleMap: {
      "display": "none"
    },
    key: 4
  }, [api_text("Market Segment  "), api_element("a", {
    attrs: {
      "href": "https://docs.google.com/presentation/d/1TDEASOEXaWFvgm8vxRktkzACphoqbuJpE6Ru_E9-2jY/edit#slide=id.g6e4a56c833_0_217",
      "target": "zO2O"
    },
    key: 5
  }, [api_text("Channels")]), api_element("select", {
    key: 6
  }, [api_element("option", {
    attrs: {
      "value": "https://edge.zpaper.com/kb/books/zpaper.com.-20200._20200.202002291553.wdz.zip"
    },
    key: 7
  }, [api_text("PAP")]), api_element("option", {
    attrs: {
      "value": ""
    },
    key: 8
  }, [api_text("Payroll")]), api_element("option", {
    attrs: {
      "value": ""
    },
    key: 9
  }, [api_text("Referrals")]), api_element("option", {
    attrs: {
      "value": ""
    },
    key: 10
  }, [api_text("Members")])]), api_element("input", {
    attrs: {
      "type": "button"
    },
    props: {
      "value": "Load"
    },
    key: 11,
    on: {
      "click": _m0 || ($ctx._m0 = api_bind($cmp.importTemplates))
    }
  }, [])]), api_element("li", {
    styleMap: {
      "text-align": "center"
    },
    key: 12
  }, [api_element("span", {
    styleMap: {
      "font-weight": "bold"
    },
    key: 13
  }, [api_text("Intake Path:"), api_element("input", {
    styleMap: {
      "width": "400px"
    },
    attrs: {
      "name": "zChannelPath",
      "placeholder": "Classification/Program/Channel"
    },
    key: 14
  }, []), api_element("br", {
    key: 15
  }, []), api_element("select", {
    attrs: {
      "name": "ClassifyName",
      "value": ""
    },
    key: 16,
    on: {
      "change": _m1 || ($ctx._m1 = api_bind($cmp.updatePath))
    }
  }, api_flatten([$cmp.classification ? api_element("option", {
    attrs: {
      "value": $cmp.classification
    },
    key: 17
  }, [api_dynamic($cmp.classification)]) : null, !$cmp.classification ? api_element("option", {
    attrs: {
      "value": ""
    },
    key: 18
  }, [api_text("-- Classification --")]) : null, api_iterator($cmp.sfClassifications, function (classification) {
    return api_element("option", {
      attrs: {
        "value": classification.Value
      },
      key: api_key(19, classification.Value)
    }, [api_dynamic(classification.Label)]);
  })])), api_element("select", {
    attrs: {
      "name": "ProgramName",
      "value": ""
    },
    key: 20,
    on: {
      "change": _m2 || ($ctx._m2 = api_bind($cmp.updatePath))
    }
  }, api_flatten([$cmp.program ? api_element("option", {
    attrs: {
      "value": $cmp.program
    },
    key: 21
  }, [api_dynamic($cmp.program)]) : null, !$cmp.program ? api_element("option", {
    attrs: {
      "value": ""
    },
    key: 22
  }, [api_text("-- Program Name --")]) : null, api_iterator($cmp.sfPrograms, function (program) {
    return api_element("option", {
      attrs: {
        "value": program.Value
      },
      key: api_key(23, program.Value)
    }, [api_dynamic(program.Label)]);
  })])), api_element("input", {
    attrs: {
      "name": "ChannelName",
      "placeholder": "-- Channel Name --"
    },
    props: {
      "value": ""
    },
    key: 24,
    on: {
      "change": _m3 || ($ctx._m3 = api_bind($cmp.updatePath))
    }
  }, [])]), api_element("textarea", {
    styleMap: {
      "display": "none"
    },
    attrs: {
      "name": "work"
    },
    key: 25
  }, [])])]), api_element("table", {
    key: 26
  }, [api_element("tbody", {
    key: 27
  }, [api_element("tr", {
    key: 28
  }, [api_element("td", {
    key: 29
  }, [api_text("Capture Files and Data from..."), api_element("ul", {
    key: 30
  }, [api_element("li", {
    key: 31
  }, [api_element("input", {
    attrs: {
      "name": "faxIn",
      "type": "checkbox"
    },
    key: 32
  }, []), api_text(" Fax (North America)"), api_element("select", {
    attrs: {
      "name": "faxNo",
      "id": api_scoped_id("faxNumber")
    },
    key: 33
  }, api_iterator($cmp.faxNumbers, function (faxNumber) {
    return api_element("option", {
      key: api_key(34, faxNumber)
    }, [api_dynamic(faxNumber)]);
  }))]), api_element("li", {
    key: 35
  }, [api_element("input", {
    attrs: {
      "name": "faxIntl",
      "type": "checkbox"
    },
    key: 36
  }, []), api_text(" Fax (International)")]), api_element("li", {
    key: 37
  }, [api_element("input", {
    attrs: {
      "name": "emailIn",
      "type": "checkbox"
    },
    key: 38
  }, []), api_text(" Email")]), api_element("li", {
    key: 39
  }, [api_element("input", {
    attrs: {
      "name": "secureIn",
      "type": "checkbox"
    },
    key: 40
  }, []), api_text(" Secure Email")]), api_element("li", {
    key: 41
  }, [api_element("input", {
    attrs: {
      "name": "scanIn",
      "type": "checkbox"
    },
    key: 42
  }, []), api_text(" Scanner Folder"), api_element("input", {
    attrs: {
      "name": "scanDir"
    },
    key: 43
  }, [])]), api_element("li", {
    key: 44
  }, [api_element("input", {
    attrs: {
      "name": "s3In",
      "type": "checkbox",
      "disabled": ""
    },
    key: 45
  }, []), api_text(" S3 Bucket"), api_element("input", {
    attrs: {
      "name": "S3Bucket",
      "placeholder": " - unavailable - ",
      "disabled": ""
    },
    key: 46
  }, [])]), api_element("li", {
    key: 47
  }, [api_element("input", {
    attrs: {
      "name": "sftpIn",
      "type": "checkbox",
      "disabled": ""
    },
    key: 48
  }, []), api_text(" SFTP"), api_element("input", {
    attrs: {
      "type": "button",
      "title": "sftp endpoint info for sftp.zpaper.com or client"
    },
    props: {
      "value": "Login"
    },
    key: 49
  }, [])]), api_element("li", {
    key: 50
  }, [api_element("input", {
    attrs: {
      "name": "uploadIn",
      "type": "checkbox"
    },
    key: 51
  }, []), api_text(" Salesforce Community")])])]), api_element("td", {
    key: 52
  }, [api_text("Deliver To..."), api_element("ul", {
    key: 53
  }, [api_element("li", {
    key: 54
  }, [api_element("input", {
    attrs: {
      "name": "faxOut",
      "type": "checkbox"
    },
    key: 55
  }, []), api_text(" Fax (North America)")]), api_element("li", {
    key: 56
  }, [api_element("input", {
    attrs: {
      "name": "intlOut",
      "type": "checkbox"
    },
    key: 57
  }, []), api_text(" Fax (International)")]), api_element("li", {
    key: 58
  }, [api_element("input", {
    attrs: {
      "name": "emailOut",
      "type": "checkbox"
    },
    key: 59
  }, []), api_text(" Email")]), api_element("li", {
    key: 60
  }, [api_element("input", {
    attrs: {
      "name": "secureOut",
      "type": "checkbox"
    },
    key: 61
  }, []), api_text(" Secure Email")]), api_element("li", {
    key: 62
  }, [api_element("input", {
    attrs: {
      "name": "paperOut",
      "type": "checkbox"
    },
    key: 63
  }, []), api_text(" Printer / Postal")]), api_element("li", {
    key: 64
  }, [api_element("input", {
    attrs: {
      "name": "smsOut",
      "type": "checkbox"
    },
    key: 65
  }, []), api_text(" SMS Text")]), api_element("li", {
    key: 66
  }, [api_element("input", {
    attrs: {
      "name": "directOut",
      "type": "checkbox"
    },
    key: 67
  }, []), api_text(" DirectMessage"), api_element("sup", {
    key: 68
  }, [api_text("†")])])])]), api_element("td", {
    key: 69
  }, [api_element("span", {
    styleMap: {
      "vertical-align": "top"
    },
    key: 70
  }, [api_text("Initial Owner/Queue :")]), api_element("select", {
    classMap: {
      "select-checkbox": true
    },
    attrs: {
      "name": "initialOwner"
    },
    key: 71
  }, api_flatten([api_element("option", {
    attrs: {
      "value": $cmp.userId,
      "selected": ""
    },
    key: api_key(72, $cmp.userId)
  }, [api_dynamic($cmp.userName)]), api_iterator($cmp.sfQueues, function (group) {
    return api_element("option", {
      attrs: {
        "value": group.Queue.Id
      },
      key: api_key(73, group.Queue.Id)
    }, [api_dynamic(group.Queue.Name)]);
  })])), api_element("br", {
    key: 74
  }, []), api_element("span", {
    styleMap: {
      "vertical-align": "top"
    },
    key: 75
  }, [api_text("Share With zGroups :")]), api_element("select", {
    classMap: {
      "select-checkbox": true
    },
    attrs: {
      "name": "sharedGroups",
      "multiple": "",
      "size": "4"
    },
    key: 76
  }, api_iterator($cmp.zpGroups, function (group) {
    return api_element("option", {
      attrs: {
        "value": group.groupId
      },
      key: api_key(77, group.groupId)
    }, [api_dynamic(group.name)]);
  })), api_element("br", {
    key: 78
  }, []), api_element("span", {
    styleMap: {
      "vertical-align": "top"
    },
    key: 79
  }, [api_text("Creates zStack and :")]), api_element("ul", {
    styleMap: {
      "margin-top": "0"
    },
    key: 80
  }, [api_element("li", {
    key: 81
  }, [api_element("input", {
    attrs: {
      "name": "createType",
      "type": "checkbox"
    },
    key: 82
  }, []), api_text(" Object Type :"), api_element("select", {
    classMap: {
      "select-checkbox": true
    },
    attrs: {
      "name": "objectType"
    },
    key: 83,
    on: {
      "change": _m4 || ($ctx._m4 = api_bind($cmp.changeObjectType))
    }
  }, api_flatten([api_element("option", {
    attrs: {
      "value": ""
    },
    key: 84
  }, [api_text("-- none --")]), api_iterator($cmp.objTypes, function (objType) {
    return api_element("option", {
      key: api_key(85, objType)
    }, [api_dynamic(objType)]);
  })]))]), api_element("li", {
    key: 86
  }, [api_text("  ⤷ RecordType →"), api_element("select", {
    classMap: {
      "select-checkbox": true
    },
    attrs: {
      "name": "recordType"
    },
    key: 87,
    on: {
      "change": _m5 || ($ctx._m5 = api_bind($cmp.changeRecordType))
    }
  }, api_flatten([api_element("option", {
    attrs: {
      "value": ""
    },
    key: 88
  }, [api_text("-- default --")]), api_iterator($cmp.recTypes, function (recType) {
    return api_element("option", {
      key: api_key(89, recType.Id)
    }, [api_dynamic(recType.Name)]);
  })]))]), api_element("li", {
    styleMap: {
      "margin-top": "10px"
    },
    key: 90
  }, [api_element("label", {
    key: 91
  }, [api_text("Use Client Action")]), api_element("input", {
    attrs: {
      "name": "clientAction",
      "type": "text",
      "placeholder": "clientCase()"
    },
    props: {
      "value": $cmp.clientAction
    },
    key: 92
  }, [])])])])]), api_element("tr", {
    key: 93
  }, [api_element("td", {
    styleMap: {
      "text-align": "center"
    },
    attrs: {
      "colspan": "3"
    },
    key: 94
  }, [$cmp.channelNo ? api_element("input", {
    attrs: {
      "type": "button"
    },
    props: {
      "value": $cmp.buttonTitle
    },
    key: 95,
    on: {
      "click": _m6 || ($ctx._m6 = api_bind($cmp.updateChannel))
    }
  }, []) : null, !$cmp.channelNo ? api_element("input", {
    attrs: {
      "type": "button",
      "disabled": ""
    },
    props: {
      "value": $cmp.buttonTitle
    },
    key: 96
  }, []) : null, api_element("input", {
    attrs: {
      "type": "button"
    },
    props: {
      "value": "Add a New Channel"
    },
    key: 97,
    on: {
      "click": _m7 || ($ctx._m7 = api_bind($cmp.insertChannel))
    }
  }, [])])])])])]), api_element("span", {
    styleMap: {
      "display": "block",
      "text-align": "center"
    },
    key: 98
  }, [api_element("sup", {
    key: 99
  }, [api_text("†")]), api_element("i", {
    key: 100
  }, [api_text("Direct messages at contracted rates (supports HL7 and FHIR protocols)")])]), api_element("table", {
    key: 101
  }, api_iterator($cmp.channelList, function (channel) {
    return [channel.id ? api_element("tr", {
      key: api_key(102, channel.name)
    }, [api_element("td", {
      key: 103
    }, [api_element("input", {
      attrs: {
        "type": "radio",
        "name": "activeChannel"
      },
      props: {
        "value": channel.id
      },
      key: 104,
      on: {
        "change": _m8 || ($ctx._m8 = api_bind($cmp.changeChannel))
      }
    }, []), api_element("h4", {
      styleMap: {
        "display": "inline"
      },
      key: 105
    }, [api_dynamic(channel.label)]), api_element("input", {
      styleMap: {
        "margin-left": "20px",
        "font-size": "smaller"
      },
      attrs: {
        "type": "button",
        "name": channel.name,
        "data-channel-id": channel.id
      },
      props: {
        "value": "Revert"
      },
      key: 106,
      on: {
        "click": _m9 || ($ctx._m9 = api_bind($cmp.revertChannel))
      }
    }, []), api_element("input", {
      styleMap: {
        "margin-left": "20px",
        "font-size": "smaller"
      },
      attrs: {
        "type": "button",
        "name": channel.name,
        "data-channel-id": channel.id
      },
      props: {
        "value": "Remove"
      },
      key: 107,
      on: {
        "click": _m10 || ($ctx._m10 = api_bind($cmp.removeChannel))
      }
    }, [])]), api_element("td", {
      classMap: {
        "option-box": true
      },
      key: 108
    }, [channel.settings.fax ? api_element("label", {
      styleMap: {
        "font-weight": "bold"
      },
      key: 109
    }, [api_text("Fax")]) : null, channel.settings.fax ? api_element("input", {
      attrs: {
        "name": "fax",
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "checked": true
      },
      key: 110
    }, []) : null, !channel.settings.fax ? api_element("label", {
      key: 111
    }, [api_text("Fax")]) : null, !channel.settings.fax ? api_element("input", {
      attrs: {
        "name": "fax",
        "type": "checkbox",
        "disabled": ""
      },
      key: 112
    }, []) : null, channel.settings.email ? api_element("label", {
      styleMap: {
        "font-weight": "bold"
      },
      key: 113
    }, [api_text("Email")]) : null, channel.settings.email ? api_element("input", {
      attrs: {
        "name": "email",
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "checked": true
      },
      key: 114
    }, []) : null, !channel.settings.email ? api_element("label", {
      key: 115
    }, [api_text("Email")]) : null, !channel.settings.email ? api_element("input", {
      attrs: {
        "name": "email",
        "type": "checkbox",
        "disabled": ""
      },
      key: 116
    }, []) : null, channel.settings.scan ? api_element("label", {
      styleMap: {
        "font-weight": "bold"
      },
      key: 117
    }, [api_text("Scan")]) : null, channel.settings.scan ? api_element("input", {
      attrs: {
        "name": "scan",
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "checked": true
      },
      key: 118
    }, []) : null, !channel.settings.scan ? api_element("label", {
      key: 119
    }, [api_text("Scan")]) : null, !channel.settings.scan ? api_element("input", {
      attrs: {
        "name": "scan",
        "type": "checkbox",
        "disabled": ""
      },
      key: 120
    }, []) : null, channel.settings.sftp ? api_element("label", {
      styleMap: {
        "font-weight": "bold"
      },
      key: 121
    }, [api_text("SFTP")]) : null, channel.settings.sftp ? api_element("input", {
      attrs: {
        "name": "sftp",
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "checked": true
      },
      key: 122
    }, []) : null, !channel.settings.sftp ? api_element("label", {
      key: 123
    }, [api_text("SFTP")]) : null, !channel.settings.sftp ? api_element("input", {
      attrs: {
        "name": "sftp",
        "type": "checkbox",
        "disabled": ""
      },
      key: 124
    }, []) : null, channel.settings.hl7 ? api_element("label", {
      styleMap: {
        "font-weight": "bold"
      },
      key: 125
    }, [api_text("HL7")]) : null, channel.settings.hl7 ? api_element("input", {
      attrs: {
        "name": "hl7",
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "checked": true
      },
      key: 126
    }, []) : null, !channel.settings.hl7 ? api_element("label", {
      key: 127
    }, [api_text("HL7")]) : null, !channel.settings.hl7 ? api_element("input", {
      attrs: {
        "name": "hl7",
        "type": "checkbox",
        "disabled": ""
      },
      key: 128
    }, []) : null, channel.settings.fhir ? api_element("label", {
      styleMap: {
        "font-weight": "bold"
      },
      key: 129
    }, [api_text("FHIR")]) : null, channel.settings.fhir ? api_element("input", {
      attrs: {
        "name": "fhir",
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "checked": true
      },
      key: 130
    }, []) : null, !channel.settings.fhir ? api_element("label", {
      key: 131
    }, [api_text("FHIR")]) : null, !channel.settings.fhir ? api_element("input", {
      attrs: {
        "name": "fhir",
        "type": "checkbox",
        "disabled": ""
      },
      key: 132
    }, []) : null])]) : null, channel.id ? api_element("tr", {
      key: api_key(133, channel.name)
    }, [api_element("td", {
      attrs: {
        "colspan": "2"
      },
      key: 134
    }, [api_element("textarea", {
      className: channel.class,
      attrs: {
        "name": "json",
        "spellcheck": false,
        "readonly": ""
      },
      key: 135,
      on: {
        "input": _m11 || ($ctx._m11 = api_bind($cmp.fillChecks))
      }
    }, [api_dynamic(channel.value)])])]) : null];
  })), $cmp.dirty ? api_element("input", {
    styleMap: {
      "float": "right",
      "margin": "10px"
    },
    attrs: {
      "type": "button"
    },
    props: {
      "value": "Save Custom Settings"
    },
    key: 136,
    on: {
      "click": _m12 || ($ctx._m12 = api_bind($cmp.handleSave))
    }
  }, []) : null, !$cmp.dirty ? api_element("input", {
    styleMap: {
      "float": "right",
      "margin": "10px"
    },
    attrs: {
      "type": "button",
      "readonly": "",
      "disabled": ""
    },
    props: {
      "value": "Saved Custom Settings"
    },
    key: 137
  }, []) : null])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_channelsConfig-host",
  shadowAttribute: "my-modules-m2o_channelsConfig"
};
