import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./masterGroup.html";
import zLightningElement from 'base/zLightningElement';
import { initializeOrg } from 'data/zpaperConfigService';
class MasterGroup extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.deploymentRecord = {};
    this.initializeFields = [];
    this.masterGroup = "";
  }
  // @track passwordsMatch = false;

  // sfPassword = "";
  // sfPasswordAgain = "";

  connectedCallback() {
    super.connectedCallback();
    let loginView = document.lightningWebComponents['login-view'];
    let userInfo = loginView && loginView.userInfo;
    if (!userInfo) {
      console.error('User is not logged in!');
      return;
    }
    let verifyOrg = document.lightningWebComponents['verify-organization'];
    let deployment = verifyOrg && verifyOrg.getDeployment();
    let sfServer = verifyOrg.getSfServer(); // use the value entered on the Verify Org form
    this.updateFields(deployment, sfServer, userInfo.username);
    // If this is the first time through, always save the data
    //this.dirty = true; //ERS210213 do not save it unless they actually made a change
  }
  updateFields(deployment, sfServer, defaultUser) {
    if (deployment) {
      let masterId = deployment["KBin_Master_ID__c"] || "";
      let zpServer = deployment["Server__c"] || "";
      let masterUser = deployment["Primary_User_Login__c"] || "";
      // let masterHint = deployment["Primary_Login_Password__c"] || "";
      // let zippiKey = deployment["zippiKey__c"] || "";

      let initialFields = [
      // { key: "KBin_Master_ID__c", label: "Master User Id", default: "- use next available -", value: masterId, readonly: true, type: "text" },
      // { key: "zippiKey__c", label: "zippi API Key", default: "- generate new API key -", value: zippiKey, readonly: true, type: "text" },
      // { key: 'SF_Server__c', label: 'Salesforce Server', default: 'REQUIRED', value: this.sfServer, readonly: true, type: "text" },
      {
        key: 'Server__c',
        label: 'zPaper Server',
        default: 'REQUIRED',
        value: zpServer,
        readonly: true,
        type: "text"
      }, {
        key: 'Primary_User_Login__c',
        label: 'Integration Username',
        default: defaultUser,
        value: masterUser,
        readonly: !!masterId,
        type: "text"
      }, {
        key: 'Primary_Login_Password__c',
        label: 'Salesforce Password',
        default: masterId ? '-- verfied --' : '-- required --',
        value: "",
        readonly: !!masterId,
        type: "password"
      }
      // { key: 'Primary_Login_Password__c__again', label: 'Salesforce Password (again)', default: 'REQUIRED', type: "password", value: "" }
      ];
      console.debug({
        initialFields
      });

      // Make sure our copy of the deployment information holds the sfServer value and primary login username.
      this.deploymentRecord = Object.assign(deployment, {
        "SF_Server__c": sfServer
      });
      if (!masterUser) {
        this.deploymentRecord["Primary_User_Login__c"] = defaultUser;
      }
      this.initializeFields = initialFields;
      this.masterGroup = masterId; //SHR211022 detect previously initialized group
      this.dirty = !masterId; //SHR240423: force initialize if Master group is not yet set

      // Update the deployment in the verify-organization component in case any later code needs it.
      let verifyOrg = document.lightningWebComponents['verify-organization'];
      verifyOrg.setDeployment(this.deploymentRecord); //SHR240404: save updates for other components to use?
    }
  }
  async saveYourself() {
    if (!this.dirty) {
      let currentValue = this.template.querySelector("input[data-fld-name='Primary_Login_Password__c']").value; //ERS210212 out of time
      if (currentValue === "") this.completed = true; //ERS210212
      return;
    }
    let check = await initializeOrg(this.deploymentRecord).then(deployedOrg => {
      console.debug({
        deployedOrg
      });
      if (deployedOrg) {
        this.updateFields(deployedOrg);
        this.completed = true;
        // Don't try this same set of data again, unless there is a changed field
        this.dirty = false;
      }
      return deployedOrg;
    }).catch(errors => {
      /* eslint-disable-next-line no-restricted-globals */
      this.completed = confirm(`InitializeOrg failed with error:\n{}\n Continue anyway?`, errors);
      return errors;
    });

    //SHR240711: better feedback if the zippi api key is not returned...
    if (check && !check.zippiKey__c) {
      console.error("Unexpected response from /initialize endpoint", check);
      alert("Initialize Master Group did not return the new API Key!\nPlease reload this wizard to continue the installation...");
    }
  }
  handleChange(evt) {
    let fldName = evt.target.dataset.fldName;
    if (fldName && this.deploymentRecord[fldName] !== evt.target.value) {
      this.deploymentRecord[fldName] = evt.target.value;
      this.dirty = true;
    }
  }
  handleKeypress(evt) {
    if ("password" === evt.target.type) {
      // if ("Primary_Login_Password__c__again" === evt.target.dataset.fldName) {
      //     this.sfPasswordAgain = evt.target.value;
      // }
      // else {
      //     this.sfPassword = evt.target.value;
      // }
      // this.passwordsMatch = this.sfPassword === this.sfPasswordAgain;
    }
  }
}
_registerDecorators(MasterGroup, {
  track: {
    deploymentRecord: 1,
    initializeFields: 1,
    masterGroup: 1
  }
})
export default _registerComponent(MasterGroup, {
  tmpl: _tmpl
});