import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./consentAgreement.html";
import zLightningElement from 'base/zLightningElement';
import { updateDeployment } from 'data/zpaperConfigService';
class ConsentAgreement extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.deploymentRecord = {};
    // @track packageList = [];
    this.isRegistered = false;
    this.agreedTimestamp = null;
    this.documentUrl = null;
  }
  get signedAgreement() {
    return this.agreedTimestamp != null;
  }
  connectedCallback() {
    super.connectedCallback();
    let loginView = document.lightningWebComponents['login-view'];
    let userInfo = loginView && loginView.userInfo;
    if (!userInfo) {
      console.error('User is not logged in!');
      return;
    }
    let verifyOrg = document.lightningWebComponents['verify-organization'];
    let deployment = verifyOrg && verifyOrg.getDeployment();
    // this.packageList = verifyOrg && verifyOrg.packageList;
    this.isRegistered = !!deployment;
    this.agreedTimestamp = deployment && deployment.User_Provisioning__c; // check the consent agreement timestamp
    this.dirty = !this.isRegistered; // no changes needed for existing deployments
    this.deploymentRecord = deployment;
  }
  async saveYourself() {
    this.completed = !!this.agreedTimestamp; //SHR211021 make sure it is signed before continuing
    if (!this.dirty || !this.agreedTimestamp) {
      //SHR211012 we are done if we made no changes or didn't check the box
      if (!this.agreedTimestamp) {
        alert('Please read and sign the Consent Agreement before proceeding...');
      }
      return;
    }

    // Update the deployment record with the timestamp and discovered package list (if Notes are empty)
    this.deploymentRecord.User_Provisioning__c = this.agreedTimestamp;
    // if (!this.deploymentRecord.Notes__c && !!this.packageList && this.packageList.length) {
    //     //TODO: update existing Notes__c without over-writing other info, or endlessly appending this list
    //     let notes = {
    //         "InstalledPackages": this.packageList
    //     }
    //     this.deploymentRecord.Notes__c = JSON.stringify(notes, null, 2);
    // }

    await updateDeployment(this.deploymentRecord).then(result => {
      // console.debug(`Result status: ${result.status}`);
      console.debug('Result:', result);
      // if (result && result.status == "success") {
      //     this.dirty = false; // Don't update this same data again, unless a field is changed
      // }
      // this.completed = !this.dirty;
      this.completed = true; //SHR211011 TODO: figure out why the result has no status?!?
      return result;
    }).catch(errors => {
      /* eslint-disable-next-line no-restricted-globals */
      this.completed = !this.deploymentRecord.Primary_Login_Password__c || confirm(errors);
      return errors;
    });
  }
  handleCheckbox(evt) {
    let agreedTo = evt.target && evt.target.checked;
    this.dirty = true; // something changed, mark it as dirty
    this.agreedTimestamp = agreedTo ? new Date().toISOString().split('T')[0] : null;
  }
}
_registerDecorators(ConsentAgreement, {
  publicProps: {
    documentUrl: {
      config: 0
    }
  },
  track: {
    deploymentRecord: 1,
    isRegistered: 1,
    agreedTimestamp: 1
  }
})
export default _registerComponent(ConsentAgreement, {
  tmpl: _tmpl
});