import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./trustedSites.html";
import zLightningElement from 'base/zLightningElement';
import { getTrustedSites, saveTrustedSites } from 'data/salesforceConfigService';
class TrustedSites extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.trustedSites = [];
  }
  connectedCallback() {
    super.connectedCallback();
    this.dirty = true; // Only save these fixed values the first time

    let verifyOrg = document.lightningWebComponents['verify-organization'];
    let deployment = verifyOrg && verifyOrg.getDeployment();
    if (deployment && deployment.Server__c) {
      getTrustedSites(deployment.Server__c).then(result => {
        this.trustedSites = result;
      }).catch(error => {
        console.error(error);
        alert(error);
      });
    } else {
      console.error("Deployment Record is missing the 'Server__c' hostname!");
    }
  }
  async saveYourself() {
    if (!this.dirty) {
      this.completed = true;
      return;
    }
    await saveTrustedSites(this.trustedSites).then(results => {
      this.completed = true;
      this.dirty = false;
      return results;
    }).catch(errors => {
      alert(errors);
      return errors;
    });
  }
}
_registerDecorators(TrustedSites, {
  track: {
    trustedSites: 1
  }
})
export default _registerComponent(TrustedSites, {
  tmpl: _tmpl
});