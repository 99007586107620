import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./registerOrganization.html";
import zLightningElement from 'base/zLightningElement';
import { getDeploymentRecord } from 'data/zpaperConfigService';
// import { createDeployment } from 'data/zpaperConfigService';

class RegisterOrganization extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.deploymentRecord = {};
    this.deploymentExists = true;
    // trust now, verify later
    this.registerFields = [];
    this.isRegistered = void 0;
    this.recName = null;
    this.userName = null;
    this.sfServer = null;
    this.sfLogin = "login.salesforce.com";
    this.zpServer = "zp99.zpaper.com";
  }
  get homePage() {
    return `https://${document.domain}`;
  }
  get caseName() {
    return `New Deployment - ${this.recName}`;
  }
  connectedCallback() {
    super.connectedCallback();
    let loginView = document.lightningWebComponents['login-view'];
    let userInfo = loginView && loginView.userInfo;
    if (!userInfo) {
      console.error('User is not logged in!');
      return;
    }
    this.sfLogin = loginView && loginView.isSandbox ? "test.salesforce.com" : "login.salesforce.com";
    this.sfServer = userInfo.urls.custom_domain || userInfo.urls.rest;
    this.sfServer = this.sfServer.split(/[/]+/)[1];
    console.debug("this.sfServer =>", this.sfServer);
    this.sfOrgId = userInfo.organization_id;
    this.recName = `${userInfo.display_name} (${userInfo.username})`;
    this.userName = userInfo.username;
    getDeploymentRecord(userInfo.organization_id).then(deployment => {
      this.isInitialized = true;
      if (deployment.message || deployment.errorMessage) {
        this.completed = false;
        this.deploymentExists = false;
        this.dirty = true; // uses the Case submit form to create the deployment record
      }
      this.updateFields(userInfo);
    }).catch(error => {
      console.error("Error received when retrieving Deployment record.");
      console.error(error);
      this.deploymentExists = false;
      this.dirty = true;
    });
  }
  updateFields(loginUser) {
    this.registerFields = [{
      key: 'retURL',
      name: 'retUrl',
      value: this.homePage,
      readonly: true,
      type: "hidden"
    }, {
      key: 'orgid',
      name: 'orgid',
      value: '00D700000009gBV',
      readonly: true,
      type: "hidden"
    }, {
      key: 'subject',
      name: 'subject',
      value: this.caseName,
      readonly: true,
      type: "hidden"
    }, {
      key: '0124u000000tQwF',
      name: 'recordType',
      value: '0124u000000tQwF',
      readonly: true,
      type: "hidden"
    }, {
      key: '00N4u000004KVpG',
      name: '00N4u000004KVpG',
      label: 'Integration Username',
      value: this.userName,
      readonly: true,
      type: "text"
    }, {
      key: '00N0g0000048lMA',
      name: '00N0g0000048lMA',
      label: 'Organization Id',
      value: this.sfOrgId,
      readonly: true,
      type: "text"
    }, {
      key: '00N4u000004KVpH',
      name: '00N4u000004KVpH',
      label: 'Organization Type',
      value: '',
      selector: true,
      options: ['Production', 'Sandbox', 'Development']
    }
    // { key: 'Name', label: 'Record Name', default: 'REQUIRED', value: this.recName, readonly: false, type: "text" },
    // { key: "Salesforce_Org_18_char__c", label: "Organization ID", value: loginUser.organization_id, readonly: true, type: "text" },
    // { key: 'SF_Server__c', label: 'Salesforce Server', default: 'REQUIRED', value: this.sfServer, readonly: true, type: "text" },
    // { key: 'SF_Login_Endpoint__c', label: 'SF Login Endpoint', value: this.sfLogin, readonly: true, type: "text" },
    ];
    console.debug({
      registerFields: this.registerFields
    });

    // Make sure our copy of the deployment information holds the minimum required fields.
    let sfOrgId15 = loginUser.organization_id.substring(0, 15);
    this.deploymentRecord = {
      "Name": this.recName,
      "Account__c": this.acctId,
      "SF_Server__c": this.sfServer,
      "Server__c": this.zpServer,
      "Primary_User_Login__c": loginUser.username,
      "Time_Zone__c": loginUser.timezone,
      "Salesforce_Org__c": sfOrgId15,
      "zPaper_Fax__c": sfOrgId15,
      //ERS - use the org Id for the fax number
      "zippiKey__c": sfOrgId15,
      //ERS - use the org Id for the zippi key
      "Package__c": "__ZMERGE",
      "Edition__c": "Merge"
    };

    // Update the deployment in the verify-organization component in case any later code needs it.
    // let verifyOrg = document.lightningWebComponents['verify-organization'];
    // verifyOrg.setDeploymentRecord(this.deploymentRecord);
  }
  async saveYourself() {
    if (!this.dirty) {
      this.completed = true;
    }
    /*
            await createDeployment(this.deploymentRecord)
                .then(deployedOrg => {
                    console.info({ deployedOrg });
                    this.updateFields(deployedOrg);
                    this.completed = true;
                    // Don't try this same set of data again, unless there is a changed field
                    this.dirty = false;
                    return deployedOrg;
                })
                .catch(errors => {
                    this.completed = confirm(errors);
                    return errors;
                });
    */
  }
  handleChange(evt) {
    let fldName = evt.target.dataset.fldName;
    if (fldName && this.deploymentRecord[fldName] !== evt.target.value) {
      this.deploymentRecord[fldName] = evt.target.value;
      this.dirty = true;
    }
  }
}
_registerDecorators(RegisterOrganization, {
  track: {
    deploymentRecord: 1,
    deploymentExists: 1,
    registerFields: 1,
    isRegistered: 1,
    recName: 1,
    userName: 1,
    sfServer: 1,
    sfLogin: 1,
    zpServer: 1
  }
})
export default _registerComponent(RegisterOrganization, {
  tmpl: _tmpl
});