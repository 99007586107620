import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./customSettings.html";
import zLightningElement from 'base/zLightningElement';
import { getCustomSettings, saveCustomSettings } from 'data/salesforceConfigService';
class CustomSettings extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.customSettings = [];
    this.settingsObject = {};
    this.updatedObject = {};
  }
  connectedCallback() {
    super.connectedCallback();
    getCustomSettings().then(settings => {
      // preserve the Custom Settings object as a flat object that contains name/value pairs
      Object.keys(settings).forEach(key => {
        this.settingsObject[key] = settings[key].value;
      });

      // ProviderRecord key may or may not be part of the Managed Package -- so check for both
      let providerRecKey = settings['ProviderRecord__c'] || '';
      let pfx = 'ZPAPER__'; //SHR210727 #82081 lookup settings by package prefix (default is 'ZPAPER__')

      let verifyOrg = document.lightningWebComponents['verify-organization'];
      let deployment = verifyOrg && verifyOrg.getDeployment();
      if (deployment) {
        if (deployment.Package__c) {
          pfx = deployment.Package__c;
        }
        /* eslint-disable-next-line no-prototype-builtins */
        if (settings.hasOwnProperty(`${pfx}ProviderRecord__c`)) {
          providerRecKey = `${pfx}ProviderRecord__c`;
        }

        // Check to see if we'll be using any defaults, and if so set the dirty flag
        this.dirty = !settings[`${pfx}Channel1__c`].value || !settings[`${pfx}Channel2__c`].value || !settings[`${pfx}server__c`].value || !settings[`${pfx}PatientRecord__c`].value || providerRecKey && !settings[providerRecKey].value || !settings[`${pfx}Records__c`].value;

        // Set the default Channels to the first 2 fax numbers...
        let faxes = (deployment.zPaper_Fax__c || '').split(',');
        let fax1 = faxes && faxes[0] ? faxes[0].trim() : '';
        let fax2 = faxes && faxes[1] ? faxes[1].trim() : '';
        settings[`${pfx}Channel1__c`].value = settings[`${pfx}Channel1__c`].value || fax1;
        this.updatedObject[`${pfx}Channel1__c`] = settings[`${pfx}Channel1__c`].value;
        settings[`${pfx}Channel2__c`].value = settings[`${pfx}Channel2__c`].value || fax2;
        this.updatedObject[`${pfx}Channel2__c`] = settings[`${pfx}Channel2__c`].value;

        // Default zpaper server is the gateway...
        settings[`${pfx}server__c`].value = settings[`${pfx}server__c`].value || 'gw.zpaper.com';
        this.updatedObject[`${pfx}server__c`] = settings[`${pfx}server__c`].value;

        // Default Patient record for HealthCloud is Account
        settings[`${pfx}PatientRecord__c`].value = settings[`${pfx}PatientRecord__c`].value || 'Contact';
        this.updatedObject[`${pfx}PatientRecord__c`] = settings[`${pfx}PatientRecord__c`].value;
        if (providerRecKey) {
          // Default Provider record for HealthCloud is Contact
          settings[providerRecKey].value = settings[providerRecKey].value || 'Account';
          this.updatedObject[providerRecKey] = settings[providerRecKey].value;
        }

        // Default Attached Records are Case and Lead
        settings[`${pfx}Records__c`].value = settings[`${pfx}Records__c.value`] || 'Case,Lead';
        this.updatedObject[`${pfx}Records__c`] = settings[`${pfx}Records__c`].value;
      }
      const keySettings = [`${pfx}Channel1__c`, `${pfx}Channel2__c`, `${pfx}server__c`, `${pfx}PatientRecord__c`, providerRecKey, `${pfx}Records__c`];
      // Flatten the map into an array that can be rendered in the template...
      this.customSettings = keySettings.map(field => settings[field]);
    }).catch(error => {
      console.error(error);
    });
  }
  async saveYourself() {
    if (!this.dirty) {
      this.completed = true;
      return;
    }
    this.updatedObject.Id = this.settingsObject.Id;
    await saveCustomSettings(this.updatedObject).then(output => {
      console.info({
        savedSettings: output
      });
      if (output.success) {
        // this.updateFields(response);
        this.updatedObject = {};
        this.completed = true;
        this.dirty = false;
      }
      return output;
    }).catch(errors => {
      alert(errors);
      return errors;
    });
  }
  handleChange(evt) {
    let fldName = evt.target.dataset.fldName;
    if (fldName) {
      this.updatedObject[fldName] = evt.target.value;
      this.dirty = true;
    }
  }

  /* eslint-disable-next-line no-unused-vars */
  handleShowChannelsWizard(evt) {
    let w = window.open("https://devers.zpaper.com/znr/zConfigChannels", 'zChannelsWizard', 'resizable=1');
    w.focus();
  }

  /* eslint-disable-next-line no-unused-vars */
  handleShowAutoDriveWizard(evt) {
    let w = window.open("https://devers.zpaper.com/znr/zConfigAD", 'zChannelsWizard', 'resizable=1');
    w.focus();
  }
}
_registerDecorators(CustomSettings, {
  track: {
    customSettings: 1,
    settingsObject: 1
  },
  fields: ["updatedObject"]
})
export default _registerComponent(CustomSettings, {
  tmpl: _tmpl
});