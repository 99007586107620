import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./oauthLogin.html";
import zLightningElement from 'base/zLightningElement';
import { getOAuthUser } from 'data/authService';
class OauthLogin extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.marquee = "";
  }
  get loginUrl() {
    return `https://zen.zpaper.com`;
  }
  get displayName() {
    return this.loggedUser ? this.loggedUser.display_name || this.loggedUser.name : "";
  }
  connectedCallback() {
    super.connectedCallback();
    console.debug('@@@@ Checking OAuth user info @@@@');
    getOAuthUser().then(info => {
      if (info.user_id === undefined) {
        console.debug("User is not logged in yet.");
        this.loggedUser = undefined;
        return;
      }
      console.debug({
        userInfo: info
      });
      this.loggedUser = info;

      // Now that we are logged in, show the accordion UI
      let showEvt = new CustomEvent('show-yourself', {
        bubbles: true,
        cancelable: true
      });
      $("#wizard")[0].dispatchEvent(showEvt);
    }).catch(errors => {
      console.warn(errors.statusText || "invalid session?");
    });
  }
}
_registerDecorators(OauthLogin, {
  publicProps: {
    marquee: {
      config: 0
    },
    loginUrl: {
      config: 1
    },
    displayName: {
      config: 1
    }
  }
})
export default _registerComponent(OauthLogin, {
  tmpl: _tmpl
});