/**
 * Gets logged in user info
 * @returns {Promise} Promise holding user info or an empty object if user is not logged in
 */
export function getLoggedInUser() {
  return new Promise((resolve, reject) => {
    console.log('getLoggedInUser called');
    fetch('/oauth/whoami').then(response => {
      if (!response.ok) {
        reject(response);
      }
      return response.json();
    }).then(json => {
      return resolve(json);
    }).catch(error => {
      reject(error);
    });
  });
}
export function getOAuthUser() {
  return new Promise((resolve, reject) => {
    console.log('getOAuthUser called');
    fetch('/oauth/userinfo', {
      method: "GET",
      credentials: "include"
    }).then(response => {
      if (!response.ok) {
        reject(response);
      }
      return response.json();
    }).then(json => {
      return resolve(json);
    }).catch(error => {
      reject(error);
    });
  });
}